import { AuthorizationService } from './authorizationService';
import { HttpService } from './httpService';
/**
 * Tests the authorization methods on the specified baseUrl. Will test OIDC first, then SAML, then user credentials.
 * Returns the first valid authorization method, or null if none are valid.
 * In the case of too many invalid requests, the datasource may return a 429 response. This will be thrown as an error.
 * @param options Include the base url of the datasource and one or more authorization methods to test
 * @returns AuthorizationService if one of the methods is valid, null otherwise
 */
export async function authorizationFactory(options) {
    const { baseUrl, abortController, getOidcToken, getSamlToken, userCredentials } = options;
    if (getOidcToken) {
        const authorization = await testAuthorizationMethod(baseUrl, { getOidcToken }, abortController);
        if (authorization) {
            return authorization;
        }
    }
    if (getSamlToken) {
        const authorization = await testAuthorizationMethod(baseUrl, { getSamlToken }, abortController);
        if (authorization) {
            return authorization;
        }
    }
    if (userCredentials) {
        const authorization = await testAuthorizationMethod(baseUrl, { userCredentials }, abortController);
        if (authorization) {
            return authorization;
        }
    }
    return null;
}
async function testAuthorizationMethod(baseUrl, authorizationMethod, abortController) {
    const authorization = new AuthorizationService(authorizationMethod);
    const httpService = new HttpService({ baseUrl, authorization });
    const success = await testEndpoint(httpService, abortController);
    if (success) {
        return authorization;
    }
    return null;
}
async function testEndpoint(httpService, abortController) {
    const response = await httpService.get('CheckAuthHeader', {
        abortController
    });
    if (response.ok || response.status == 404) {
        return true;
    }
    if (response.status == 429) {
        throw new Error('Too many sign in attempts. Please try again later.');
    }
    return false;
}
