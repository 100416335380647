import './toolbarButton.css';
import React from 'react';
import { IconButton } from '@itwin/itwinui-react';
import { KeyboardBinding } from '../keybinding';
import { ToolbarButtonWithChildren } from './toolbarButtonWithChildren';
/**
 * IconButton or DropdownMenu with child items.
 * @internal
 */
export function ToolbarButton({ action: { groupLabel, icon, itemTooltip, shortcutCode, startGroup, submenuItems, ...iconButtonProps } }) {
    if (submenuItems === null || submenuItems === void 0 ? void 0 : submenuItems.length) {
        return (React.createElement(ToolbarButtonWithChildren, { childItems: submenuItems, icon: icon, iconButtonProps: iconButtonProps }));
    }
    const { title, ...rest } = iconButtonProps;
    function label() {
        const labelText = itemTooltip || title;
        const shortcut = iconButtonProps.disabled ? null : (React.createElement(KeyboardBinding, { value: shortcutCode }));
        return (React.createElement(React.Fragment, null,
            labelText,
            shortcut));
    }
    return (React.createElement(IconButton, { ...rest, label: label() }, icon));
}
