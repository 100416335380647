import React, { useMemo } from 'react';
import { Kbd, KbdKeys } from '@itwin/itwinui-react';
/**
 * Wrapper around KBD to display multi-key keyboard binding.
 * @param props0 KeyboardBindingCell props
 * @returns JSX.Element | null
 */
export function KeyboardBinding({ value }) {
    const keys = useMemo(() => {
        var _a;
        const keys = value === null || value === void 0 ? void 0 : value.toUpperCase().split('+');
        return (_a = keys === null || keys === void 0 ? void 0 : keys.sort(byImportance).map(toSpecialKey)) !== null && _a !== void 0 ? _a : [];
    }, [value]);
    if (!keys.length || keys.every((key) => key == '')) {
        return null;
    }
    return (React.createElement("div", { "data-keys": value }, keys.map((key, index) => (React.createElement(React.Fragment, { key: key },
        React.createElement(Kbd, null, key),
        index < keys.length - 1 && ' + ')))));
}
function byImportance(a, b) {
    const aImportance = keyImportance(a);
    const bImportance = keyImportance(b);
    return bImportance - aImportance;
}
function keyImportance(key) {
    switch (key.toUpperCase()) {
        case 'COMMAND':
        case 'CTRL':
            return 4;
        case 'APPLE':
        case 'WINDOWS':
            return 3;
        case 'ALT':
        case 'OPTION':
            return 2;
        case 'SHIFT':
            return 1;
        default:
            return 0;
    }
}
function toSpecialKey(key) {
    switch (key.toUpperCase()) {
        case 'ADD':
            return 'NumPad +';
        case 'APPLE':
            return KbdKeys.Apple;
        case 'BACKSLASH':
            return '\\';
        case 'BACKSPACE':
            return KbdKeys.Backspace;
        case 'BACKQUOTE':
            return '`';
        case 'BRACKETLEFT':
            return '[';
        case 'BRACKETRIGHT':
            return ']';
        case 'COMMA':
            return ',';
        case 'COMMAND':
            return KbdKeys.Command;
        case 'CTRL':
            return KbdKeys.Control;
        case 'DIVIDE':
            return 'NumPad /';
        case 'DECIMAL':
            return 'NumPad .';
        case 'DOWN':
            return KbdKeys.Down;
        case 'ENTER':
            return KbdKeys.Enter;
        case 'EJECT':
            return KbdKeys.Eject;
        case 'EQUAL':
            return '=';
        case 'LEFT':
            return KbdKeys.Left;
        case 'META':
        case 'WINDOWS':
            return KbdKeys.Windows;
        case 'MINUS':
            return '-';
        case 'MULTIPLY':
            return 'NumPad *';
        case 'OPTION':
            return KbdKeys.Option;
        case 'PERIOD':
            return '.';
        case 'QUOTE':
            return "'";
        case 'RIGHT':
            return KbdKeys.Right;
        case 'SEMICOLON':
            return ';';
        case 'SHIFT':
            return KbdKeys.Shift;
        case 'SLASH':
            return '/';
        case 'SUBTRACT':
            return 'NumPad -';
        case 'UP':
            return KbdKeys.Up;
        default:
            return key;
    }
}
