import './keyboardHint.css';
import React from 'react';
import { SvgBolt } from '@itwin/itwinui-icons-react';
import { Icon, Tooltip } from '@itwin/itwinui-react';
import { KeyboardBinding } from './keyboardBinding';
/**
 * Tooltip to show keyboard shortcut hint.
 * @param keyboardHintProps KeyboardHint props
 * @returns JSX.Element | null
 */
export function KeyboardHint({ shortcutCode, ...iconProps }) {
    if (!shortcutCode) {
        return null;
    }
    return (React.createElement(Tooltip, { content: React.createElement(KeyboardBinding, { value: shortcutCode }), placement: "right" },
        React.createElement(Icon, { className: "keyboard-hint", "data-testid": "keyboard-hint", ...iconProps },
            React.createElement(SvgBolt, null))));
}
